import { useEffect, useRef } from "react";
import { VideoDevice } from "../../containers/CameraSideBySide";
import styles from './index.module.scss'
import { JsonView, collapseAllNested, darkStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import { useControls } from "leva";
import { isSlider, isStringArray } from "../../utils/typeCheck";
import type { Schema,InputWithSettings,  NumberSettings , OnChangeHandler, InputOptions} from "leva/dist/declarations/src/types/index";
import { LevaInputs } from "leva";
import { SelectInput } from "leva/dist/declarations/src/components/Select/select-types";

interface WebcamStreamProps{
  videoDevice: VideoDevice
  enable:boolean
}
type CapabilityChangeHandler = <TVALUE extends object, TKEY extends keyof MediaTrackCapabilities>(key:TKEY, value:TVALUE) => void;
const getControlsForArray = (options:string[], value: string, onChange:OnChangeHandler):SelectInput & InputOptions=>({
  options,value,
  onChange
})
const getControlsForSlider = (options:{min:number,max:number,step?:number}={min:0,max:0}, value: number, onChange:OnChangeHandler):InputWithSettings<number, NumberSettings> & InputOptions=>({
  type:LevaInputs.NUMBER,
  value,
  ...options,
  onChange
})

const getControls = ({capabilities, settings}: VideoDevice, handleChange:CapabilityChangeHandler):Schema=>{
  let controls:Schema = {}
  Object.keys(capabilities).map((key) => {
    const prop = capabilities[key];
    const settingKey = Object.keys(settings).find(setKey => setKey === key);
    const val = settingKey ? settings[settingKey] : undefined
    if(prop){
      if(isStringArray(prop)){
        controls[key]=getControlsForArray(prop as string[], val?`${val}`:"", (v)=> handleChange(key, v))
      }else if(isSlider(prop)){
        controls[key]= getControlsForSlider(prop as {min:number, max:number, step:number}, val as number, (v)=> handleChange(key, v))
      }
    }

  })
  return controls
}
const WebCamStream: React.FC<WebcamStreamProps> = ({videoDevice,enable})=>{
  const vd = ({...videoDevice, capabilities: {
    ...videoDevice.capabilities,
    aspectRatio: {min:0,max:10}
  }});
  const {capabilities,settings,track,device,stream, constraints} = vd
  const{label}=device;
  const videoRef = useRef<HTMLVideoElement>(null)
  const handleChange:CapabilityChangeHandler = (key, value) => track.applyConstraints({[key]: value})
  
  useEffect(()=>{
    
      const timer = setTimeout(()=>{if(videoRef.current){videoRef.current.srcObject = stream}},3000)
      // videoRef.current.scrollTo
      return ()=> clearTimeout(timer)
  }, [videoRef, stream, enable])
  useControls(label,()=>({...getControls(vd, handleChange)}), {render: (get) => enable, order:10, collapsed: !enable})
  // const [{sampleR}, setl] = useControls("", ()=>({  sampleR: {value:7, min:0,max:10, onChange: (kk)=>{}}  }))
  // // @ts-ignore
  // const { exposureMode } = useControls(`dev2: ${device.label}`, {  exposureMode: {options: capabilities.exposureMode},  })
  // const [{enable}, setEnable] = useControls(`enable ${label}`, ()=>({enable: {value:true}}))
  if(!enable)
    return<></>;
  // console.log(cont)
  return <div className={styles.video} >
    <video ref={videoRef} autoPlay />
    <JsonView data={vd} style={darkStyles}/>
  </div>
}
export default WebCamStream