export const isStringArray = <TVALUE extends Object | []>(value:TVALUE):boolean=> {
   if (Array.isArray(value)) {
       var somethingIsNotString = false;
       value.forEach((item)=>{
          if(typeof item !== 'string'){
           return false
          }
       })
       if(!somethingIsNotString && value.length > 0){
          return true
       }
    }
    return false
}
export const isSlider = <TVALUE extends Object | []>(value:TVALUE):boolean=> {
   const min = Object.keys(value).find(k => k === "min") !== undefined
   const max = Object.keys(value).find(k => k === "max") !== undefined
   // const step = Object.keys(value).find(k => k === "step") !== undefined
   return min && max
}