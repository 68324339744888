import styles from './index.module.scss'
import WebcamStream from '../../components/WebcamStream'
import { useEffect, useState } from 'react'
import { useControls } from 'leva'

export interface VideoDevice{
    device:MediaDeviceInfo
    stream:MediaStream
    capabilities: MediaTrackCapabilities
    settings: MediaTrackSettings
    track: MediaStreamTrack
    constraints: MediaTrackConstraints
}

const mapToObject = (map:Map<string, any>) => Object.fromEntries(map.entries());
mapToObject(new Map([['a', 1], ['b', 2]])); // {a: 1, b: 2}

const EnableDisableCam: React.FC<{videoDevices: VideoDevice[]}> = ({videoDevices})=>{
    const [controls, setControls] = useState<{[key:string]: {value:boolean, label:string}}>({})
    console.log('controls',controls)
    const enCont = useControls("Enable",controls)
    console.log('enCont',enCont)
    useEffect(()=> {
        const obj = mapToObject(new Map(videoDevices.map(vd => [vd.device.deviceId, {value:true, label:vd.device.label}])))
        setControls(obj)
    }, [videoDevices])
        // @ts-ignore
    return<>
    {videoDevices.map((vd,i) => 
    <WebcamStream key={vd.device.deviceId} videoDevice={vd} enable={enCont[vd.device.deviceId] === undefined ? true : enCont[vd.device.deviceId]}/>
    )}
     </>
}
const CameraSideBySide: React.FC = ()=> {
    const [videoDevices, setVideoDevice] = useState<VideoDevice[]>([])
    useEffect(() => {
        const enumDevs = ()=>{
        navigator.mediaDevices.enumerateDevices().then(async devs=> {
            const videoDevs  = (await Promise.all(devs.map(async (device)=>{
                if(device.kind === "videoinput"){
                    if(videoDevices.find(vd => vd.device.deviceId === device.deviceId) === undefined){
                        const stream = await navigator.mediaDevices.getUserMedia({video:{deviceId: device.deviceId}})
                        const [track] = stream.getVideoTracks();
                        const capabilities = track?.getCapabilities && track.getCapabilities();
                        const settings = track.getSettings();
                        const constraints = track.getConstraints();
                        const videoDevice: VideoDevice = {
                            device,
                            stream,
                            track,
                            capabilities,
                            settings,
                            constraints,
                        };
                    return videoDevice
                }
            }
            }))).filter(vdr => vdr) as VideoDevice[]
            if (videoDevs.length > 0)
            {setVideoDevice([...videoDevices, ...videoDevs])}
        })}
        const timer = setInterval(enumDevs, 10000);
        enumDevs();
        return ()=>{
            clearInterval(timer);
        }
    }, [videoDevices]);
    return (
        <div className={styles.parent}>
            <EnableDisableCam videoDevices={videoDevices}/>
        </div>
    )
}

export default CameraSideBySide