import React from 'react';
import logo from './logo.svg';
import './App.css';
import CameraSideBySide from './containers/CameraSideBySide'
function App() {
  return (
    <div className="App">
      <CameraSideBySide />
    </div>
  );
}

export default App;
